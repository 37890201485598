const c = [
	() => import("../../../src/routes/__layout.svelte"),
	() => import("../components/error.svelte"),
	() => import("../../../src/routes/data-workshop/__layout.svelte"),
	() => import("../../../src/routes/data-workshop/index.svelte"),
	() => import("../../../src/routes/data-workshop/shapefile.svelte"),
	() => import("../../../src/routes/data-workshop/csv.svelte"),
	() => import("../../../src/routes/data-workshop/dhs.svelte"),
	() => import("../../../src/routes/public/index.svelte"),
	() => import("../../../src/routes/public/link_problem.svelte"),
	() => import("../../../src/routes/login.svelte"),
	() => import("../../../src/routes/auth/__layout.svelte"),
	() => import("../../../src/routes/auth/reset_password.svelte"),
	() => import("../../../src/routes/auth/data-workshop/index.svelte"),
	() => import("../../../src/routes/auth/logout.svelte"),
	() => import("../../../src/routes/auth/admin/__layout.svelte"),
	() => import("../../../src/routes/auth/admin/index.svelte"),
	() => import("../../../src/routes/auth/admin/algo_uses/index.svelte"),
	() => import("../../../src/routes/auth/admin/algo_uses/[slug]/__layout.svelte"),
	() => import("../../../src/routes/auth/admin/algo_uses/[slug]/index.svelte"),
	() => import("../../../src/routes/auth/admin/algo_uses/[slug]/visualisations/index.svelte"),
	() => import("../../../src/routes/auth/admin/algo_uses/[slug]/version.svelte"),
	() => import("../../../src/routes/auth/admin/new_user.svelte"),
	() => import("../../../src/routes/auth/admin/builds/index.svelte"),
	() => import("../../../src/routes/auth/admin/algos/index.svelte"),
	() => import("../../../src/routes/auth/admin/algos/[job_definition]/__layout.svelte"),
	() => import("../../../src/routes/auth/admin/algos/[job_definition]/index.svelte"),
	() => import("../../../src/routes/auth/admin/algos/[job_definition]/version.svelte"),
	() => import("../../../src/routes/auth/admin/algos/[job_definition]/schema.svelte"),
	() => import("../../../src/routes/auth/admin/runs/index.svelte"),
	() => import("../../../src/routes/auth/runs/index.svelte"),
	() => import("../../../src/routes/auth/runs/[team_slug]/index.svelte"),
	() => import("../../../src/routes/auth/runs/[team_slug]/[parcel_path]/index.svelte"),
	() => import("../../../src/routes/docs/__layout.svelte"),
	() => import("../../../src/routes/docs/index.svelte"),
	() => import("../../../src/routes/docs/[slug].svelte")
];

const d = decodeURIComponent;

export const routes = [
	// src/routes/index.ts
	[/^\/$/],

	// src/routes/data-workshop/index.svelte
	[/^\/data-workshop\/?$/, [c[0], c[2], c[3]], [c[1]]],

	// src/routes/data-workshop/shapefile.svelte
	[/^\/data-workshop\/shapefile\/?$/, [c[0], c[2], c[4]], [c[1]]],

	// src/routes/data-workshop/test_data.js
	[/^\/data-workshop\/test_data\/?$/],

	// src/routes/data-workshop/csv.svelte
	[/^\/data-workshop\/csv\/?$/, [c[0], c[2], c[5]], [c[1]]],

	// src/routes/data-workshop/dhs.svelte
	[/^\/data-workshop\/dhs\/?$/, [c[0], c[2], c[6]], [c[1]]],

	// src/routes/public/index.svelte
	[/^\/public\/?$/, [c[0], c[7]], [c[1]]],

	// src/routes/public/link_problem.svelte
	[/^\/public\/link_problem\/?$/, [c[0], c[8]], [c[1]]],

	// src/routes/login.svelte
	[/^\/login\/?$/, [c[0], c[9]], [c[1]]],

	// src/routes/auth/reset_password.svelte
	[/^\/auth\/reset_password\/?$/, [c[0], c[10], c[11]], [c[1]]],

	// src/routes/auth/data-workshop/index.svelte
	[/^\/auth\/data-workshop\/?$/, [c[0], c[10], c[12]], [c[1]]],

	// src/routes/auth/logout.svelte
	[/^\/auth\/logout\/?$/, [c[0], c[10], c[13]], [c[1]]],

	// src/routes/auth/admin/index.svelte
	[/^\/auth\/admin\/?$/, [c[0], c[10], c[14], c[15]], [c[1]]],

	// src/routes/auth/admin/algo_uses/index.svelte
	[/^\/auth\/admin\/algo_uses\/?$/, [c[0], c[10], c[14], c[16]], [c[1]]],

	// src/routes/auth/admin/algo_uses/[slug]/index.svelte
	[/^\/auth\/admin\/algo_uses\/([^/]+?)\/?$/, [c[0], c[10], c[14], c[17], c[18]], [c[1]], (m) => ({ slug: d(m[1])})],

	// src/routes/auth/admin/algo_uses/[slug]/visualisations/index.svelte
	[/^\/auth\/admin\/algo_uses\/([^/]+?)\/visualisations\/?$/, [c[0], c[10], c[14], c[17], c[19]], [c[1]], (m) => ({ slug: d(m[1])})],

	// src/routes/auth/admin/algo_uses/[slug]/version.svelte
	[/^\/auth\/admin\/algo_uses\/([^/]+?)\/version\/?$/, [c[0], c[10], c[14], c[17], c[20]], [c[1]], (m) => ({ slug: d(m[1])})],

	// src/routes/auth/admin/new_user.svelte
	[/^\/auth\/admin\/new_user\/?$/, [c[0], c[10], c[14], c[21]], [c[1]]],

	// src/routes/auth/admin/builds/index.svelte
	[/^\/auth\/admin\/builds\/?$/, [c[0], c[10], c[14], c[22]], [c[1]]],

	// src/routes/auth/admin/algos/index.svelte
	[/^\/auth\/admin\/algos\/?$/, [c[0], c[10], c[14], c[23]], [c[1]]],

	// src/routes/auth/admin/algos/[job_definition]/index.svelte
	[/^\/auth\/admin\/algos\/([^/]+?)\/?$/, [c[0], c[10], c[14], c[24], c[25]], [c[1]], (m) => ({ job_definition: d(m[1])})],

	// src/routes/auth/admin/algos/[job_definition]/version.svelte
	[/^\/auth\/admin\/algos\/([^/]+?)\/version\/?$/, [c[0], c[10], c[14], c[24], c[26]], [c[1]], (m) => ({ job_definition: d(m[1])})],

	// src/routes/auth/admin/algos/[job_definition]/schema.svelte
	[/^\/auth\/admin\/algos\/([^/]+?)\/schema\/?$/, [c[0], c[10], c[14], c[24], c[27]], [c[1]], (m) => ({ job_definition: d(m[1])})],

	// src/routes/auth/admin/runs/index.svelte
	[/^\/auth\/admin\/runs\/?$/, [c[0], c[10], c[14], c[28]], [c[1]]],

	// src/routes/auth/runs/index.svelte
	[/^\/auth\/runs\/?$/, [c[0], c[10], c[29]], [c[1]]],

	// src/routes/auth/runs/[team_slug]/index.svelte
	[/^\/auth\/runs\/([^/]+?)\/?$/, [c[0], c[10], c[30]], [c[1]], (m) => ({ team_slug: d(m[1])})],

	// src/routes/auth/runs/[team_slug]/[parcel_path]/index.svelte
	[/^\/auth\/runs\/([^/]+?)\/([^/]+?)\/?$/, [c[0], c[10], c[31]], [c[1]], (m) => ({ team_slug: d(m[1]), parcel_path: d(m[2])})],

	// src/routes/docs/index.svelte
	[/^\/docs\/?$/, [c[0], c[32], c[33]], [c[1]]],

	// src/routes/docs/[slug].svelte
	[/^\/docs\/([^/]+?)\/?$/, [c[0], c[32], c[34]], [c[1]], (m) => ({ slug: d(m[1])})],

	// src/routes/api/list_covariates.ts
	[/^\/api\/list_covariates\/?$/],

	// src/routes/api/integrate/[job_id]/function_status/[function_status].ts
	[/^\/api\/integrate\/([^/]+?)\/function_status\/([^/]+?)\/?$/],

	// src/routes/api/integrate/[job_id]/batch_status/[...status]/index.ts
	[/^\/api\/integrate\/([^/]+?)\/batch_status(?:\/(.*))?\/?$/],

	// src/routes/api/headless/parcel/[team_slug]/index.ts
	[/^\/api\/headless\/parcel\/([^/]+?)\/?$/],

	// src/routes/api/headless/parcel/[team_slug]/[parcel_path]/index.ts
	[/^\/api\/headless\/parcel\/([^/]+?)\/([^/]+?)\/?$/],

	// src/routes/api/headless/parcel/[team_slug]/[parcel_path]/trigger.ts
	[/^\/api\/headless\/parcel\/([^/]+?)\/([^/]+?)\/trigger\/?$/],

	// src/routes/api/headless/parcel/[team_slug]/[parcel_path]/files/[...filename].ts
	[/^\/api\/headless\/parcel\/([^/]+?)\/([^/]+?)\/files(?:\/(.*))?$/],

	// src/routes/api/headless/new/[algo_use_slug].ts
	[/^\/api\/headless\/new\/([^/]+?)\/?$/],

	// src/routes/api/client/presigned_download_url.ts
	[/^\/api\/client\/presigned_download_url\/?$/],

	// src/routes/api/client/presigned_upload_url.ts
	[/^\/api\/client\/presigned_upload_url\/?$/],

	// src/routes/api/client/list_parcels.ts
	[/^\/api\/client\/list_parcels\/?$/],

	// src/routes/api/client/delete_file.ts
	[/^\/api\/client\/delete_file\/?$/],

	// src/routes/api/client/list_files.ts
	[/^\/api\/client\/list_files\/?$/],

	// src/routes/api/client/run_parcel.ts
	[/^\/api\/client\/run_parcel\/?$/],

	// src/routes/api/admin/list_batch_job_definitions.ts
	[/^\/api\/admin\/list_batch_job_definitions\/?$/],

	// src/routes/api/admin/list_ecr_builds.ts
	[/^\/api\/admin\/list_ecr_builds\/?$/],

	// src/routes/api/admin/list_commits.ts
	[/^\/api\/admin\/list_commits\/?$/],

	// src/routes/api/ping.ts
	[/^\/api\/ping\/?$/]
];

// we import the root layout/error components eagerly, so that
// connectivity errors after initialisation don't nuke the app
export const fallback = [c[0](), c[1]()];